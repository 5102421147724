import { getMarketplaceList } from "@/services";
import {
  UPDATE_MARKETPLACE_LIST,
  UPDATE_MARKETPLACE_TOTAL_ITEMS,
} from "./constants.marketplace.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  async fetchMarketplaceList({ commit },
    { limit, offset, search, status, hierarchy, recommendation_top, }) {
    //alert(`*** START: fetchMarketplaceList: actions.marketplace.store.js ***`);
    //alert(`*** limit=${limit}, offset=${offset}, ` +
    //      `search=${search}, status=${status}, hierarchy=${hierarchy}, ` +
    //      `recommendation_top=${recommendation_top}` +
    //      `: actions.marketplace.store.js ***`
    //);
    const data = await getMarketplaceList({
      limit: limit,
      offset: offset,
      name: search,
      status: status,
      hierarchy: hierarchy,
      recommendation_top: recommendation_top,
    }); // End of  const data = await getMarketplaceList({...})
    console.debug({ data });
    commit(UPDATE_MARKETPLACE_LIST, data?.items);
    commit(UPDATE_MARKETPLACE_TOTAL_ITEMS, data?.total);
  }, // End of async fetchMarketplaceList({ commit }, {...}) {...}
}; // End of export default {...}
