import { getAddress } from '@ethersproject/address';

export function truncate(str, n, frontChars, backChars, separator) {
  /**
   * str: Input string
   * n: Number of character want to display
   * frontChars: Number of characters in front of separator
   * backChars: Number of characters in back of separator
   * seperator: Symbol want to display, default "..."
   */
  const sep = separator || '...';
  const sepLen = sep.length;
  if (str.length < n - sepLen) {
    return str;
  }
  return str.substr(0, frontChars) + sep + str.substr(str.length - backChars);
}

export function isAddress(value) {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

export const getFileName = (url) => {
  if (url) {
    const splitted = url.split('/');
    return splitted[splitted.length - 1];
  }
  return '';
};
