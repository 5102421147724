import Web3 from 'web3';
import { SAVED_CONNECTOR_KEY } from '../constants/common.constant';
import UtilNotify from '@/utils/notify.util';
import { mapGetters, mapMutations } from 'vuex';
import { CONNECTORS } from '../constants/types.constant';
export default {
  mounted() {},
  computed: {
    ...mapGetters({
      account: 'wallet/account',
    }),
  },
  methods: {
    ...mapMutations({
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
      setToConnectChain: 'wallet/UPDATE_TO_CONNECT_CHAIN',
    }),
    async connectWallet(chainId, id) {
      try {
        console.log('chain:', chainId);
        this.$store.commit('setting/CHANGE_GLOBAL_LOADING', true);
        await this.handleDisconnect();
        if (id === CONNECTORS.WALLET_CONNECT) {
          // eslint-disable-next-line no-async-promise-executor
          await this.$wcConnector.connect(chainId);
          localStorage.setItem(SAVED_CONNECTOR_KEY, CONNECTORS.WALLET_CONNECT);
          this.$connector.setup();
          await this.updateUserInfo();
          this.listeningToEvents();
        } else {
          await this.$metamaskConnector.connect(chainId);
          localStorage.setItem(SAVED_CONNECTOR_KEY, CONNECTORS.METAMASK);
          this.$connector.setup();
          await this.updateUserInfo();
          this.listeningToEvents();
        }
        this.$root.$emit('chain-switched', true);
      } catch (err) {
        UtilNotify.error(this.$notify, err);
      } finally {
        this.$store.commit('setting/CHANGE_GLOBAL_LOADING', false);
      }
    },
    async onInit() {
      try {
        this.$store.commit('setting/CHANGE_GLOBAL_LOADING', true);

        this.listeningToEvents();
        await this.updateUserInfo();
        if (!this.$root._events['switch-metamask-chain']?.length) {
          this.$root.$on('switch-metamask-chain', async (chainId) => {
            if (this.account) {
              await this.switchChain(chainId);
            } else {
              this.setToConnectChain(chainId);
              this.setModalRequestState(true);
            }
          });
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.commit('setting/CHANGE_GLOBAL_LOADING', false);
      }
    },
    listeningToEvents() {
      if (!this.$connector.adapter) return;
      this.$connector.adapter.subscribeToEvents(
        this.onChainChange.bind(this),
        this.onAccountChange.bind(this),
        this.onDisconnect.bind(this)
      );
    },
    async updateUserInfo() {
      if (!this.$connector.provider) return;
      const web3 = new Web3(this.$connector.provider);
      const _chainId = await web3.eth.getChainId();
      const accounts = await web3.eth.getAccounts();
      const provider = {
        chainId: _chainId,
        address: accounts?.length ? accounts[0] : '',
      };
      this.$store.commit('wallet/UPDATE_WALLET', { provider });
      this.$store.dispatch('wallet/loginWithAddress', provider.address);
    },
    async onDisconnect() {
      try {
        await this.handleDisconnect();
      } catch (err) {
        // do nothing
      }
    },
    async onAccountChange(account) {
      this.$store.commit('wallet/UPDATE_WALLET', {
        provider: {
          address: account,
        },
      });
      this.$store.dispatch('wallet/loginWithAddress', account);
    },
    async onChainChange(chainId) {
      this.$store.commit('wallet/UPDATE_WALLET', {
        provider: {
          chainId: chainId,
        },
      });
    },
    async switchChain(targetChain) {
      try {
        const connector = this.$connector.adapter;
        if (localStorage.getItem(SAVED_CONNECTOR_KEY) == CONNECTORS.WALLET_CONNECT) {
          await this.handleDisconnect();
          await this.connectWallet(targetChain, CONNECTORS.WALLET_CONNECT);
        } else {
          await connector.switchChain(targetChain);
        }
        this.$root.$emit('chain-switched', true);
        return;
      } catch (err) {
        console.log('in switch chain err', err);
        UtilNotify.error(this.$notify, {
          message:
            'Chain is not supported. If you are using metamask mobile, please switch chain manually!',
        });
      }
    },
    async handleDisconnect() {
      this.$store.commit('setting/CHANGE_GLOBAL_LOADING', true);
      try {
        const connector = this.$connector.adapter;
        await connector?.disconnect();
        console.log('disconencted');
      } catch (err) {
        // do nothing
      }
      this.$connector.provider = null;
      this.$connector.adapter = null;
      this.$store.commit('wallet/RESET_WALLET_STATE');
      localStorage.removeItem(SAVED_CONNECTOR_KEY);
      this.$store.commit('setting/CHANGE_GLOBAL_LOADING', false);
    },
  },
  beforeDestroy() {
    this.$root.$off('switch-metamask-chain');
  },
};
