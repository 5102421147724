import cloneDeep from "lodash-es/cloneDeep";
import {
  UPDATE_WALLET,
  UPDATE_MAIN_TOKEN_BALANCE,
  RESET_WALLET_STATE,
  UPDATE_TOKEN_BALANCES,
  UPDATE_AUTH_TOKEN,
  UPDATE_SHOW_MODAL_CONNECT,
  UPDATE_TO_CONNECT_CHAIN,
} from "./constants.wallet.store";
export default {
  [UPDATE_WALLET](state, { provider }) {
    state.provider = { ...state.provider, ...provider };
  },

  [UPDATE_MAIN_TOKEN_BALANCE](state, payload) {
    state.mainTokenBalance = payload;
  },
  [RESET_WALLET_STATE](state) {
    state.provider = {
      address: "",
      chainId: "",
      isMetamask: false,
      balance: 0,
    };
    state.mainTokenBalance = 0;
    state.balanceTokensMap = {};
    state.authToken = {};
  },
  [UPDATE_TOKEN_BALANCES](state, payload) {
    const oldMap = cloneDeep(state.balanceTokensMap);
    payload.forEach((item) => {
      const { balance, address } = item;
      const key = address.toLowerCase();
      oldMap[key] = balance;
    });
    state.balanceTokensMap = cloneDeep(oldMap);
  },
  [UPDATE_AUTH_TOKEN](state, payload) {
    state.authToken = { ...payload };
  },
  [UPDATE_SHOW_MODAL_CONNECT](state, val) {
    console.debug({ val });
    state.shouldShowModalConnect = val;
  },
  [UPDATE_TO_CONNECT_CHAIN](state, chain) {
    console.debug({ chainUpdate: chain });
    state.toConnectChainId = chain;
  },
};
