import cloneDeep from "lodash-es/cloneDeep";
import {
  UPDATE_MARKETPLACE_LIST,
  UPDATE_MARKETPLACE_TOTAL_ITEMS,
} from "./constants.marketplace.store";
export default {
  [UPDATE_MARKETPLACE_LIST](state, payload) {
    state.marketplaceList = cloneDeep(payload);
  },
  [UPDATE_MARKETPLACE_TOTAL_ITEMS](state, payload) {
    state.totalItems = Number(payload);
  },
};
