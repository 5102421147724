import Web3 from 'web3';
import { RPC_URL_KEY } from '@/constants/common.constant';
import BigNumber from 'bignumber.js';
import { compare } from '@/utils/bignumber.util';
import { CHAIN_ID, EIP_1559_CHAIN_ID } from '../constants/chains.constant';

const FALLBACK_GAS_PRICE = 500000000000;
const FALLBACK_GAS_LIMIT = 500000;

const getChainPriorityGas = (chain) => {
  switch (chain) {
    case CHAIN_ID.POLYGON_MAINNET:
    case CHAIN_ID.POLYGON_TESTNET:
      return Web3.utils.toWei('60', 'Gwei');
    case CHAIN_ID.ETH_MAINNET:
    case CHAIN_ID.GOERLI_TESTNET:
      return Web3.utils.toWei('2', 'Gwei');
    case CHAIN_ID.BINANCE_MAINNET:
    case CHAIN_ID.BINANCE_TESTNET:
      return Web3.utils.toWei('3', 'Gwei');
    case CHAIN_ID.ASTAR_MAINNET:
    case CHAIN_ID.ASTAR_SHIBUYA_TESTNET:
      return Web3.utils.toWei('3', 'Gwei');
    case CHAIN_ID.AVAX_MAINNET:
    case CHAIN_ID.AVAX_TESTNET:
      return Web3.utils.toWei('3', 'Gwei');
    default:
      return Web3.utils.toWei('0', 'Gwei');
  }
};

const getWeb3 = (provider) => {
  let web3 = null;
  if (provider) {
    web3 = new Web3(provider);
  } else {
    const rpcUrl = localStorage.getItem(RPC_URL_KEY);
    web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
  }
  web3.eth.extend({
    methods: [
      {
        name: 'chainId',
        call: 'eth_chainId',
        outputFormatter: web3.utils.hexToNumber,
      },
    ],
  });
  return web3;
};

const getBalanceCoin = async (provider, address) => {
  try {
    const web3 = getWeb3(provider);
    const wei = await web3.eth.getBalance(address);
    return web3.utils.fromWei(wei, 'ether');
  } catch (error) {
    return 0;
  }
};

const getGasPrice = async (provider = null) => {
  const myWeb3 = getWeb3(provider);
  const rawGasPrice = await myWeb3.eth.getGasPrice();
  const gasPrice =
    typeof rawGasPrice === 'string' && rawGasPrice.slice(0, 2) === '0x' && rawGasPrice.length > 2
      ? parseInt(rawGasPrice, 16)
      : parseInt(rawGasPrice);
  return compare('gt')(gasPrice)(0) ? gasPrice : FALLBACK_GAS_PRICE;
};

const getBlockNumber = async (provider = null) => {
  try {
    const myWeb3 = getWeb3(provider);
    const result = await myWeb3.eth.getBlockNumber();

    return result;
  } catch (error) {
    console.error('getBlockNumber error:>>', error);
    return 0;
  }
};

const estimateGas = async (myContract, action, params, overwrite) => {
  try {
    if (!overwrite.gasPrice || overwrite.gasPrice == '') {
      const gasPrice = await getGasPrice();
      overwrite.gasPrice = gasPrice;
    }

    let gas = await myContract.methods[action](...params).estimateGas(overwrite);
    if (!gas) {
      gas = FALLBACK_GAS_LIMIT;
    }
    const fee = BigNumber(overwrite.gasPrice).times(gas);
    const value =
      overwrite.value && overwrite.value !== '0' ? fee.plus(BigNumber(overwrite.value)) : fee;
    console.debug({ value, gas });

    return gas;
  } catch (error) {
    console.error(error);
    return FALLBACK_GAS_LIMIT;
  }
};

const queryRaw = async (provider, abi, addressContract, action, params, overwrite) => {
  const myWeb3 = getWeb3(provider);
  const myContract = new myWeb3.eth.Contract(abi, addressContract);
  const rs = await myContract.methods[action](...params).call(overwrite);
  return rs;
};

const sendRawTx = async (
  provider,
  abi,
  addressContract,
  action,
  params,
  overwrite,
  slippage = 1,
  gasPriceSlippage = 1,
  callback
) => {
  console.log('testing->gasLimit slippage', slippage);
  console.log('testing->gasPrice slippage', gasPriceSlippage);
  const myWeb3 = getWeb3(provider);
  const myContract = new myWeb3.eth.Contract(abi, addressContract);
  const gasPrice = await getGasPrice(provider);
  overwrite.gasPrice = BigNumber(gasPrice).multipliedBy(gasPriceSlippage).toFixed(0);
  console.log('testing->gasPrice after slippage', overwrite.gasPrice);
  // for eip-1559
  const eip1559Chains = Object.values(EIP_1559_CHAIN_ID);
  const chainId = await myWeb3.eth.getChainId();
  if (eip1559Chains.includes(chainId)) {
    const maxPriorityFeePerGas = getChainPriorityGas(chainId);
    overwrite.maxFeePerGas = overwrite.gasPrice;
    overwrite.maxPriorityFeePerGas = BigNumber(overwrite.gasPrice).gt(maxPriorityFeePerGas)
      ? maxPriorityFeePerGas
      : BigNumber(overwrite.gasPrice).multipliedBy(0.9).toFixed(0);
  }

  const gas = await estimateGas(myContract, action, params, overwrite, provider);
  overwrite.gasLimit = BigNumber(gas).multipliedBy(slippage).toFixed(0);
  console.log('testing->gasLimit after slippage', overwrite.gasLimit);

  if (!callback) {
    return await myContract.methods[action](...params).send(overwrite);
  } else {
    myContract.methods[action](...params)
      .send(overwrite)
      .once('transactionHash', (hash) => {
        callback(null, hash, false);
      })
      .once('confirmation', (_, hash) => {
        callback(null, hash, true);
      })
      .once('error', (error) => {
        callback(error, null);
      });
  }
};

export { getWeb3, getBalanceCoin, getGasPrice, sendRawTx, getBlockNumber, queryRaw };
