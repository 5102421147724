import { UPDATE_ALLOWANCE } from './constants.allowance.store';
import { approve, getAllowanceCoin } from '@/utils/erc20.util';
const ETH_KEY = '0x0000000000000000000000000000000000000000';
import { contracts } from '@/constants/contracts.constant';

export default {
  async getAllowance({ commit, rootState }, { tokenAddress }) {
    const { chainId, address } = rootState.wallet.provider;
    if (!address || tokenAddress == ETH_KEY) {
      return 1;
    }
    const spender = contracts.projectManagement[chainId];
    const key = `${chainId}${address}${tokenAddress}${spender}`;
    let allowance = await getAllowanceCoin(tokenAddress, address, spender);
    commit(UPDATE_ALLOWANCE, { key, allowance });
    return allowance;
  },
  async approveToken({ dispatch, rootState }, { tokenAddress, provider }) {
    const { chainId, address } = rootState.wallet.provider;
    if (!address || tokenAddress == ETH_KEY) {
      return;
    }
    const spender = contracts.projectManagement[chainId];
    let rs = await approve(provider, address, tokenAddress, spender);
    await dispatch('getAllowance', { tokenAddress });
    return rs;
  },
};
