import actions   from "./actions.hierarchy.store";
import mutations from "./mutations.hierarchy.store";
import state     from "./state.hierarchy.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
