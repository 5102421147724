import { getProjectsList, getProjectDetail } from '@/services';
import { getLink } from '@/utils/link.util';
import { getFileName } from '@/utils/common.util';
import Vue from 'vue';
import {
  UPDATE_PROJECTS_LIST,
  UPDATE_SELECTED_PROJECT,
  UPDATE_TOTAL_PROJECTS,
  UPDATE_PROJECT_DETAIL_MAP,
} from './constants.projects.store';
import { getSoldAmount } from '@/services/blockchain/project';
export default {
  async fetchProjects({ commit }, { limit, offset, search }) {
    const data = await getProjectsList({
      limit,
      offset,
      name: search,
    });
    commit(UPDATE_PROJECTS_LIST, data?.items);
    commit(UPDATE_TOTAL_PROJECTS, data?.total);
  },
  async fetchProjectDetail({ commit, rootState }, id) {
    const result = await getProjectDetail(id);
    console.debug({ result });
    const data = {
      ...result,
      website_url: getLink(result.website_url),
      twitter_url: getLink(result.twitter_url),
      image_uploaded_name: getFileName(result.nft_images_url),
      json_uploaded_name: getFileName(result.nft_json_url),
      whitelist_uploaded_name: getFileName(result.presale_whitelist_url),
      reveal_uploaded_name: getFileName(result.reveal_thumbnail_url),
      banner_uploaded_name: getFileName(result.banner_url),
      thumbnail_uploaded_name: getFileName(result.thumbnail_url),
      formattedDescription: result.description,
    };
    const chainId = rootState?.wallet?.provider?.chainId || -1;
    const provider = Vue.prototype?.$connector?.provider;
    if (provider && chainId === result?.chain_id) {
      const presale_sold_amount = await getSoldAmount({
        provider,
        chainId,
        isPresale: true,
        projectId: result?.contract_project_id,
      });
      const publicsale_sold_amount = await getSoldAmount({
        provider,
        chainId,
        isPresale: false,
        projectId: result?.contract_project_id,
      });
      if (presale_sold_amount) {
        data.presale_sold_amount = presale_sold_amount;
      }
      if (publicsale_sold_amount) {
        data.publicsale_sold_amount = publicsale_sold_amount;
      }
    }

    commit(UPDATE_SELECTED_PROJECT, data);
    commit(UPDATE_PROJECT_DETAIL_MAP, [data]);
  },
};
