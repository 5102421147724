import actions from "./actions.marketplace.store";
import mutations from "./mutations.marketplace.store";
import state from "./state.marketplace.store";

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
