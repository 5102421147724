import ProjectManagementAbi from '@/abis/project-management.abi';
import Erc721Abi from '@/abis/erc721.abi.json';
import { sendRawTx, getWeb3, queryRaw } from '@/utils/web3.util';
import { contracts } from '@/constants/contracts.constant';
import BigNumber from 'bignumber.js';
import { getSignature } from '../projects';
import { ZERO_FEE_CHAINS } from '@/constants/chains.constant';
import { ZERO_ADDRESS } from '@/env';
const ZeroFeeChainIds = new Set(ZERO_FEE_CHAINS);

export const saveProject = async ({
  provider,
  account,
  chainId,
  backendId,
  projectName,
  baseTokenURI,
  saleData,
  hasWhitelist,
  canReveal,
  transferUnsoldDisabled,
  signature,
  symbol,
  paymentToken,
}) => {
  console.debug({
    provider,
    account,
    chainId,
    backendId,
    projectName,
    baseTokenURI,
    saleData,
    hasWhitelist,
    canReveal,
    transferUnsoldDisabled,
    signature,
    symbol,
    paymentToken,
  });
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'createERC721Project',
    [
      backendId,
      [projectName, symbol, baseTokenURI],
      saleData,
      [hasWhitelist, canReveal, transferUnsoldDisabled],
      paymentToken || ZERO_ADDRESS,
      signature,
    ],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};

export const startProject = async ({ provider, account, chainId, projectId }) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'startProject',
    [projectId],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};

export const revealProject = async ({ provider, account, chainId, projectId, baseTokenURI }) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'revealProject',
    [projectId, baseTokenURI],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};

export const buyNFT = async ({
  provider,
  buyer,
  chainId,
  isPresale,
  amount,
  projectId,
  price,
  backendId,
  isNativeToken = true,
}) => {
  const web3 = getWeb3(provider);
  const params = [projectId, amount];
  if (isPresale) {
    const signature = await getSignature({
      projectId: backendId,
      amount: amount,
    });
    params.push(signature);
  }
  const priceInWei = isNativeToken
    ? web3.utils.toWei(new BigNumber(amount).multipliedBy(price).toFixed(), 'ether')
    : '0';
  const overwrite = {
    from: buyer,
    value: priceInWei,
  };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    isPresale ? 'buyPresale' : 'buy',
    params,
    overwrite,
    amount * 1.2,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};

export const getOwnedAmount = async ({ provider, buyer, chainId, isPresale, projectId }) => {
  const res = await queryRaw(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    isPresale ? 'getPresaleAmountOf' : 'getPublicsaleAmountOf',
    [projectId, buyer],
    {
      from: buyer,
    }
  );
  return res;
};

export const getSoldAmount = async ({ provider, chainId, isPresale, projectId }) => {
  try {
    const res = await queryRaw(
      provider,
      ProjectManagementAbi,
      contracts.projectManagement[chainId],
      isPresale ? 'getPresaleSoldAmount' : 'getPublicsaleSoldAmount',
      [projectId]
    );
    console.log('get sold amount', res);
    return BigNumber(res).toNumber();
  } catch {
    console.log('get sold amount zero');
    return 0;
  }
};

export const getRemainAmount = async ({ provider, contractAddress }) => {
  const maxSupply = await queryRaw(provider, Erc721Abi, contractAddress, 'maxSupply', []);
  const totalSupply = await queryRaw(provider, Erc721Abi, contractAddress, 'totalSupply', []);
  return maxSupply - totalSupply;
};

export const stopProject = async ({ provider, account, chainId, projectId }) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'closeProject',
    [projectId],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1
  );
  return res;
};

export const claimUnsold = async ({ provider, account, chainId, projectId, amount }) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'claimItems',
    [projectId, amount],
    overwrite,
    1.5,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};

export const getPendingFee = async ({ provider, account, chainId, projectId }) => {
  const pendingFee = await queryRaw(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'getPendingFee',
    [projectId],
    {
      from: account,
    }
  );
  const web3 = getWeb3(provider);
  return web3.utils.fromWei(pendingFee, 'ether');
};

export const claimFee = async ({ provider, account, chainId, projectId }) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    ProjectManagementAbi,
    contracts.projectManagement[chainId],
    'claimFee',
    [projectId],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(chainId)) ? 0 : 1.2
  );
  return res;
};
