import http from './axios';
import { API_VERSION_1_PREFIX } from '@/env';

export const getProjectsList = async ({
  limit = 10,
  offset = 0,
  name = '',
  orderBy = 'created_at',
}) => {
  try {
    const response = await http.get(`${API_VERSION_1_PREFIX}/projects`, {
      params: {
        limit,
        offset,
        name,
        order_by: orderBy,
        sort_order: 'desc',
      },
    });

    return response;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const createProject = async (formData) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects`, formData);
  return data;
};

export const startProject = async (projectId) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/start`);
  return data;
};

export const deleteProject = async (projectId) => {
  const data = await http.delete(`${API_VERSION_1_PREFIX}/projects/${projectId}`);
  return data;
};

export const getProjectDetail = async (id) => {
  const response = await http.get(`${API_VERSION_1_PREFIX}/projects/${id}`);

  return response;
};

export const getNotice = async () => {
  try {
    const response = await http.get(`${API_VERSION_1_PREFIX}/notices`);
    if (!response) throw 'No content found';
    return {
      content: response?.content,
      content_jp: response?.content_jp,
    };
  } catch (err) {
    return '';
  }
};

export const updateNotice = async (content, content_jp) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/notices`, {
    content,
    content_jp,
  });
  return data;
};

export const getPRFrame = async () => {
  try {
    const response = await http.get(`${API_VERSION_1_PREFIX}/promote`);
    if (!response) throw 'No content found';
    return {
      content: response?.content,
      content_jp: response?.content_jp,
    };
  } catch (err) {
    return '';
  }
};

export const updatePRFrame = async (content, content_jp) => {
  const data = await http.post(`${API_VERSION_1_PREFIX}/promote`, {
    content,
    content_jp,
  });
  return data;
};

export const getSignature = async ({ projectId, amount }) => {
  try {
    const res = await http.get(`${API_VERSION_1_PREFIX}/projects/${projectId}/signature`, {
      params: {
        action: 'buyPresale',
        amount: amount,
      },
    });
    if (!res || !res.signature) {
      throw new Error('Signature not found');
    }
    return res.signature;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getSignatureForCreateContractProject = async (projectId) => {
  const res = await http.get(
    `${API_VERSION_1_PREFIX}/projects/${projectId}/signature?action=create`
  );
  return res;
};

export const toggleShowHide = async (projectId, isHidden) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/hide`, {
    is_hidden: isHidden,
  });
  return res;
};

export const editProject = async (projectId, formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}`, formData);
  return res;
};

export const uploadImg = async (formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/image/upload`, formData);
  return res;
};

export const uploadWhitelist = async (projectId, formData) => {
  const res = await http.post(`${API_VERSION_1_PREFIX}/projects/${projectId}/whitelist`, formData);
  return res;
};

export const downloadCSV = async (startTime, endTime) => {
  const res = await http.get(`${API_VERSION_1_PREFIX}/export/sales`, {
    params: {
      from_date: startTime,
      to_date: endTime,
    },
    responseType: 'blob',
  });
  return res;
};
