import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/lang/en.json';
import ja from '@/lang/ja.json';
import { LOCALE, FALLBACK_LOCALE, APP_VERSION, APP_NAME } from '@/env';
import axios from 'axios';

Vue.use(VueI18n);
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale:
    JSON.parse(window.localStorage.getItem(['vuex', APP_NAME, APP_VERSION].join('-')))?.setting
      ?.locale || LOCALE,
  fallbackLocale: FALLBACK_LOCALE,
  silentTranslationWarn: true,
  messages: {
    en,
    ja,
  },
});

export const setI18nLanguage = (locale) => {
  i18n.locale = locale;

  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);
};

export default i18n;
