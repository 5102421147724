import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { localize } from 'vee-validate';

import en from '@/lang/en.json';
import ja from '@/lang/ja.json';

// Install lang validate here
localize({
  en,
  ja,
});

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});
// Trigger to build
// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
