import abi from '@/abis/erc20.abi.json';
import { getWeb3, sendRawTx } from '@/utils/web3.util';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { ZERO_FEE_CHAINS } from '@/constants/chains.constant';
import Vue from 'vue';
const ZeroFeeChainIds = new Set(ZERO_FEE_CHAINS);

export const approve = async (provider, account, tokenAddress, spender) => {
  const overwrite = { from: account };
  const res = await sendRawTx(
    provider,
    abi,
    tokenAddress,
    'approve',
    [spender, ethers.constants.MaxUint256],
    overwrite,
    1,
    ZeroFeeChainIds.has(String(parseInt(provider.chainId, 16))) ? 0 : 1
  );
  return res;
};

export const getAllowanceCoin = async (tokenAddress, ethAddress, spender) => {
  try {
    const provider = Vue.prototype?.$connector?.provider;
    const myWeb3 = getWeb3(provider);
    const myContract = new myWeb3.eth.Contract(abi, tokenAddress);
    let allowance = await myContract.methods.allowance(ethAddress, spender).call();
    let decimals = await myContract.methods.decimals().call();
    return new BigNumber(allowance).dividedBy(Math.pow(10, decimals)).toFixed();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getDecimals = async (provider, tokenAddress) => {
  try {
    const myWeb3 = getWeb3(provider);
    const myContract = new myWeb3.eth.Contract(abi, tokenAddress);
    return await myContract.methods.decimals().call();
  } catch (error) {
    console.error(error);
    return 0;
  }
};
