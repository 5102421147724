import { CHAIN_ID } from "@/env";

export default () => ({
  provider: {
    isMetaMask: false,
    chainId: -1,
    address: "",
    balance: 0,
  },
  authToken: {},
  mainTokenBalance: 0,
  balanceTokensMap: {},
  shouldShowModalConnect: false,
  toConnectChainId: CHAIN_ID,
});
