import jwtDecoder from "jwt-decode";

import { CHAIN_INFO } from "@/constants/chains.constant";

export const connectedChainInfo = (state) => {
  const chainIdConnected = state.provider.chainId;
  if (chainIdConnected && chainIdConnected !== -1) {
    return CHAIN_INFO[chainIdConnected] || {};
  }
  return {};
};

export const account = (state) => {
  const wallet = state.provider.address;
  if (wallet) return wallet;
  return "";
};

export const accessToken = (state) => {
  const token = state.authToken.access_token;
  if (token) return token;
  return "";
};

export const accessTokenType = (state) => {
  const tokenType = state.authToken.token_type;
  if (tokenType) return tokenType;
  return "";
};

export const loggedUserRole = (state) => {
  const token = state.authToken.access_token;
  if (token) {
    const decodedToken = jwtDecoder(token);
    return decodedToken.role;
  }
  return "";
};
