import {
  UPDATE_HIERARCHY,
  UPDATE_NAME_EN,
  UPDATE_NAME_JA,
} from "./constants.hierarchy.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  [UPDATE_HIERARCHY](state, payload) {
    // alert(`1st_payload=${payload}`);
    state.hierarchy = payload;
    // alert(`state.hierarchy=${state.hierarchy}: mutations.hierarchy.store.js`);
  },
  [UPDATE_NAME_EN](state, payload) {
    // alert(`1st_payload=${payload}`);
    state.name_en = payload;
    // alert(`state.name_en=${state.name_en}: mutations.hierarchy.store.js`);
  },
  [UPDATE_NAME_JA](state, payload) {
    // alert(`1st_payload=${payload}`);
    state.name_ja = payload;
    // alert(`state.name_ja=${state.name_ja}: mutations.hierarchy.store.js`);
  },
};
