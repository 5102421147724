import http from "./axios";
import { API_VERSION_1_PREFIX } from "@/env";
// ======+=========+=========+=========+=========+=========+=========+=========E
export const getMarketplaceList = async ({
  limit = undefined, // CardListのNFT枚数デフォルト値。
  offset = 0,
  name = "",
  orderBy = "created_at",
  status = undefined,
  sortOrder = "desc",
  hierarchy = undefined,
  recommendation_top = undefined,
}) => {
  try {
    let params = {
      // limit: limit,
      offset: offset,
      name: name,
      order_by: orderBy,
      status: status,
      sort_order: sortOrder,
      hierarchy: hierarchy,
      recommendation_top: recommendation_top,
    }; // End of let params = {...}
    //alert(`params: ${JSON.stringify(params)}: marketplace.js`);
    // --+---------+---------+---------+---------+---------+---------+---------E
    if (limit !== undefined) {params.limit = limit;}
    // --+---------+---------+---------+---------+---------+---------+---------E
    const res = await http.get(`${API_VERSION_1_PREFIX}/marketplace/projects`, {
      params: params,
    });
    // alert(`*** res=${JSON.stringify(res)}: getMarketplaceList: marketplace.js ***`);
    // alert(`*** END  : getMarketplaceList(): marketplace.js ***`);
    return res;
  } catch (error) {
    console.log(error);
    return null;
  } // End of try {...} catch (error) {...}
}; // End of export const getMarketplaceList = async ({...]} => {...}
