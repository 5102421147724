import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import { APP_NAME, APP_VERSION } from "@/env";
// ------+---------+---------+---------+---------+---------+---------+---------E
import wallet from "./store/wallet/index.wallet.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
import allowance from "./store/allowance/index.allowance.store.js";
import hierarchy from "./store/hierarchy/index.hierarchy.store";
import projects from "./store/projects/index.projects.store";
import marketplace from "./store/marketplace/index.marketplace.store";
import setting from "./store/setting/index.setting.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
Vue.use(Vuex);
// ------+---------+---------+---------+---------+---------+---------+---------E
const persistenceOptions = {
  key: ["vuex", APP_NAME, APP_VERSION].join("-"),
  storage: window.localStorage,
};
// ------+---------+---------+---------+---------+---------+---------+---------E
export default new Vuex.Store({
  modules: {
    wallet,
    allowance,
    hierarchy,
    projects,
    marketplace,
    setting,
  },
  plugins: [new VuexPersistence(persistenceOptions).plugin],
});
