import cloneDeep from "lodash-es/cloneDeep";
import {
  UPDATE_PROJECTS_LIST,
  UPDATE_PROJECT_DETAIL_MAP,
  UPDATE_SELECTED_PROJECT,
  UPDATE_TOTAL_PROJECTS,
} from "./constants.projects.store";
export default {
  [UPDATE_PROJECTS_LIST](state, payload) {
    state.projectsList = cloneDeep(payload);
  },

  [UPDATE_PROJECT_DETAIL_MAP](state, payload) {
    const oldMap = cloneDeep(state.projectDetailMap);
    payload.forEach((item) => {
      const { id } = item;

      oldMap[id] = { ...item };
    });
    state.projectDetailMap = cloneDeep(oldMap);
  },
  [UPDATE_TOTAL_PROJECTS](state, payload) {
    state.totalProjects = payload;
  },
  [UPDATE_SELECTED_PROJECT](state, payload) {
    state.selectedProject = { ...payload };
  },
};
