import {
  UPDATE_HIERARCHY,
  UPDATE_NAME_EN,
  UPDATE_NAME_JA,
} from "./constants.hierarchy.store";
// ------+---------+---------+---------+---------+---------+---------+---------E
export default {
  setHierarchy({ commit }, {hierarchy, name_en, name_ja}) {
    // alert(`START: setHierarchy(hierarchy=${hierarchy}, name_en=${name_en}): action.hierarchy.store.js`);
    commit(UPDATE_HIERARCHY, hierarchy);
    commit(UPDATE_NAME_EN, name_en);
    commit(UPDATE_NAME_JA, name_ja);
    // alert(`END  : setHierarchy: action.hierarchy.store.js`);
  },
};
