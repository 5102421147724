import { setI18nLanguage } from '@/plugins/vue-i18n.plugin';
import { CHANGE_LOCALE, CHANGE_GLOBAL_LOADING } from './constants.setting.store';
export default {
  [CHANGE_LOCALE](state, payload) {
    state.locale = payload;
    setI18nLanguage(payload);
  },
  [CHANGE_GLOBAL_LOADING](state, payload) {
    state.globalLoading = payload;
  },
};
