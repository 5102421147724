import i18n from '@/plugins/vue-i18n.plugin';
import ModalTxReject from '@/components/modals/tx-rejected.modal';
import ModalTxComplete from '@/components/modals/tx-succeeded.modal';

export const error = ($notification, error, $modal) => {
  if (error.code && error.code == 4001) {
    return $modal.show(
      ModalTxReject,
      {},
      {
        name: 'modal-tx-rejected',
        width: '330',
        classes: 'rounded-lg',
        height: 'auto',
        adaptive: true,
        reset: true,
        scrollable: true,
      }
    );
  }

  return $notification({
    type: 'error',
    title: i18n.t(error.title) || i18n.t('NOTIFICATION_ERROR'),
    text: i18n.t(formatError(error)),
  });
};

export const success = ($notification, success) => {
  return $notification({
    type: 'success',
    title: i18n.t(success.title) || i18n.t('NOTIFICATION_SUCCESS'),
    text: i18n.t(success.message) || success,
  });
};

export const complete = ($modal, txHash) => {
  return $modal.show(
    ModalTxComplete,
    { txHash },
    {
      name: 'modal-tx-succeeded',
      width: '330',
      classes: 'rounded-lg',
      height: 'auto',
      adaptive: true,
      reset: true,
      scrollable: true,
    }
  );
};

function formatError(error) {
  if (error.code && error.code == 4001) {
    return 'TX_MODAL_REJECT_TEXT';
  }
  if (error && (typeof error === 'string' || error instanceof String)) {
    return error;
  }
  if (error.message) {
    return error.message;
  }
  return 'Undefined Error';
}

export default {
  error,
  success,
  complete,
  formatError,
};
