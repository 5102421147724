import { UPDATE_WALLET, UPDATE_AUTH_TOKEN } from "./constants.wallet.store";
import { getBalanceCoin } from "@/utils/web3.util";
import { login } from "@/services";

export default {
  async updateBalance({ commit, state }) {
    try {
      const provider = state.provider;
      let balance = "0";
      if (provider.address) {
        const [balanceNative] = await Promise.all([
          getBalanceCoin(null, provider.address),
        ]);

        balance = balanceNative;
      }
      commit(UPDATE_WALLET, { provider: { balance } });
      return balance;
    } catch (error) {
      return;
    }
  },

  async loginWithAddress({ commit }, address) {
    const data = await login(address);
    console.debug({ data });
    commit(UPDATE_AUTH_TOKEN, data);
  },
};
